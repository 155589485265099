import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { includes, merge, map } from 'lodash'
import moment from 'moment'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import NavigationButtons from 'components/bookings/NavigationButtons'

class GiftDatePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fetchedMonths: [],
      isLoading: false,
      availabilities: {},
      chosenDate: this.props.chosenDate
    }

    this.isDisabledForDate = this.isDisabledForDate.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnActivateDateChange = this.handleOnActivateDateChange.bind(this)
    this.handleOnValidate = this.handleOnValidate.bind(this)
  }

  componentDidMount() {
    this.handleOnActivateDateChange({
      activeStartDate: Date.now()
    })
  }

  handleOnChange(value) {
    this.setState({ chosenDate: value })
    this.props.onDateChosen(value)
  }

  handleOnValidate() {
    if (this.context.handleValidationSuccessful) {
      this.context.handleValidationSuccessful()
    }
  }

  handleOnActivateDateChange(options) {
    const { activity, offer, giftExpirationDate } = this.props
    const { fetchedMonths, availabilities } = this.state

    const month = moment(options.activeStartDate).format('YYYY-MM')
    const date = moment(options.activeStartDate).format('YYYY-MM-DD')
    const endDate = moment(giftExpirationDate).format('YYYY-MM-DD')

    if (!includes(fetchedMonths, month)) {
      this.setState({ isLoading: true })

      fetch(
        `/activities/${activity.id}/offers/${
          offer.id
        }/availabilities?start_date=${date}&end_date=${endDate}&${this.formatVariantsQueryParams()}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            // TODO: Handle error
          }
        })
        .then((body) => {
          fetchedMonths.push(month)
          this.setState({
            availabilities: merge(availabilities, body.availabilities),
            loading: false,
            fetchedMonths: fetchedMonths
          })
        })
    }
  }

  isDisabledForDate(options) {
    const { availabilities } = this.state
    const date = moment(options.date).startOf('day')
    const tomorrow = moment().add(1, 'days').startOf('day')
    const formattedDate = date.format('YYYY-MM-DD')
    const availability = availabilities[formattedDate]

    if (availability && availability.price && availability.slots.length > 0) {
      return date < tomorrow
    } else {
      return true
    }
  }

  formatVariantsQueryParams() {
    return map(this.props.lineItems, (lineItem) => {
      return `variants[${lineItem.price_variant_id}]=${lineItem.quantity}`
    }).join('&')
  }

  render() {
    return (
      <div className="booking-module">
        <p className="help-block">
          Les demandes de réservations sont confirmées sous réserve de disponibilités chez le partenaire. (Certains prestataires n'ont pas la possibilité d'indiquer en temps réel leurs disponibilités sur le calendrier mis à votre disposition.)
          <br />
          En cas de besoin ou si les dates sont grisées dans le calendrier, merci de
          contacter le service client à l'adresse suivante :{' '}
          <a href="mailto: hello@babasport.fr">hello@babasport.fr</a>
        </p>
        <Calendar
          locale="FR-fr"
          minDetail="month"
          formatShortWeekday={(locale, date) =>
            date.toLocaleString(locale, { weekday: 'short' })[0].toUpperCase()
          }
          showNeighboringMonth={false}
          tileDisabled={this.isDisabledForDate}
          onChange={this.handleOnChange}
          value={this.state.chosenDate}
          onActiveStartDateChange={this.handleOnActivateDateChange}
        />
        <NavigationButtons
          goPrevious={this.context.goPrevious}
          submitLabel="Suivant"
          handleValidate={this.handleOnValidate}
          disableSubmitButton={!this.state.chosenDate}
        />
      </div>
    )
  }
}

GiftDatePicker.propTypes = {
  chosenDate: PropTypes.object,
  offer: PropTypes.object,
  activity: PropTypes.object,
  onDateChosen: PropTypes.func,
  lineItems: PropTypes.array,
  giftExpirationDate: PropTypes.string
}
GiftDatePicker.contextTypes = {
  handleValidationSuccessful: PropTypes.func,
  goPrevious: PropTypes.func
}
GiftDatePicker.displayName = 'GiftDatePicker'

export default GiftDatePicker
