import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import GoogleSource from 'components/geo/google/GeocodingSource'
import { APIProvider } from '@vis.gl/react-google-maps/dist/index.umd'

const LocationAutocomplete = ({ searchInputSelector, selectors }) => {
  const didSelectResult = useCallback((result) => {
    $(searchInputSelector).val(result.resolvedAddress)
    $(selectors.houseNumber).val(result.houseNumber)
    $(selectors.route).val(result.route)
    $(selectors.city).val(result.city)
    $(selectors.postalCode).val(result.postalCode)
    $(selectors.country).val(result.country)
    $(selectors.state).val(result.state)
    $(selectors.county).val(result.county)
    $(selectors.lat).val(result.lat)
    $(selectors.lng).val(result.lng)
  }, [searchInputSelector, selectors])

  useEffect(() => {
    const source = new GoogleSource(didSelectResult)
    $(searchInputSelector).autocomplete(source)

    return () => {
      $(searchInputSelector).autocomplete("destroy")
    }
  }, [searchInputSelector, didSelectResult])

  return null
}

LocationAutocomplete.propTypes = {
  searchInputSelector: PropTypes.string.isRequired,
  selectors: PropTypes.shape({
    houseNumber: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired
  }).isRequired
}


const WrappedLocationAutocomplete = (props) => (
  <APIProvider apiKey={process.env.GOOGLE_API_KEY}>
    <LocationAutocomplete {...props} />
  </APIProvider>
)

export default WrappedLocationAutocomplete
